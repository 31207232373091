.brand {
    width: 180px;
    height: 5.2rem;
    display: block;
    transition: height .15s;
    position: relative;

    img {
        display: block;
        height: auto;
        width: auto;
        max-height: 100%;
        max-width: 100%;
    }

    .icon-brand {
        fill: #fff;
        transition: opacity $transition;
    }
}

.main-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $bg-dark;
    z-index: 100;
    transition: background-color .05s linear;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 $grid-padding-mobile;
        height: $main-header-height;
        transition: height $transition;
        transform-origin: 0 0;
    }

    .brand-scroll {
        display: none;
        transition: fill $transition;
    }

    &.menu-scrolled {
        background-color: #fff;
        box-shadow: 0 0 3rem rgba(#000, .2);

        .icon-brand {
            fill: $header-dark;
        }
    }

    &.menu-narrow {
        .container {
            height: 6rem;
        }

        .brand {
            height: 42px;
            width: 38px;

            .icon-brand {
                &:not(.brand-scroll) {
                    max-width: none;
                    height: 0;
                    opacity: 0;
                    display: none;
                }

                &.brand-scroll {
                    display: block;
                }
            }
        }
    }
}

.main-menu {
    z-index: 101;
    position: fixed;
    top: 0;
    left: $padding-mobile;
    right: $padding-mobile;
    transform: translateX(0);
    text-transform: uppercase;
    font-family: $font-headers;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    a {
        &:not(.btn) {
            color: $menu-color;
            transition: color $transition;

            span {
                @extend %underline;
                &:after {
                    background: #fff;
                }

                .menu-scrolled & {

                    &:after {
                        @extend %gradient-horizontal;
                    }
                }
            }

            &:hover {
                color: #fff;
            }
        }

        &.active {
            span {
                &::after {
                    transform: translateY(50%) scaleX(1);
                }
            }
        }
    }

    .btn {
        line-height: 46px;
        height: 46px;
        min-width: 170px;
    }

    .menu-open & {
        transform: translateX(0);
    }
}

.menu-open {
    overflow-y: hidden;
}

.nav-btn {
    position: relative;
    border: 0 none;
    background-color: transparent;
    outline: 0;
    padding: 0;
    margin: 0;
    height: 1.6rem;
    width: 3rem;
    z-index: 101;

    span {
        display: block;
        height: 0;
        width: 100%;
        border-bottom: 2px solid #fff;
        position: absolute;
        left: 0;
        transform-origin: 0% 50%;
        transition: .3s ease;

        &:first-child {
            top: 0;

            .menu-open & {
                transform: rotate(45deg);
            }
        }

        &:nth-child(2) {
            top: 8px;

            .menu-open & {
                transform: scale(0);
            }
        }

        &:last-child {
            top: 16px;

            .menu-open & {
                top: 21px;
                transform: rotate(-45deg);
            }
        }
    }

    .menu-scrolled & {
        span {
            border-color: $accent;

            .menu-open & {
                border-color: #fff;
            }
        }
    }
}

@media (max-width: $tablet-max) {
    .main-menu {
        top: 0;
        right: 0;
        bottom: 0;
        left: auto;
        transition: transform .3s linear;
        transform: translateX($tablet-max);
        overflow-y: auto;
        width: 480px;
        max-width: 100%;

        ul {
            flex-direction: column;
            background-color: rgba($bg-dark, .95);
            padding: $padding-mobile;
            width: 100%;
            min-height: 100%;
        }

        li {
            text-align: center;
            width: 100%;
        }

        a {
            &:not(.btn) {
                display: block;
                padding: .5rem 0;
                font-size: 2rem;

                &.active {
                    color: #fff;
                    span {
                        &:after {
                            background: #fff;
                        }
                    }
                }
            }
        }

        .btn {
            margin-top: 2rem;
        }
    }
}

@media (min-width: $desktop-min) {

    body {
        padding-top: $main-header-height;
    }

    .main-menu {
        position: static;

        a {
            &:not(.btn) {
                padding: 1rem 2rem;

                .menu-scrolled & {
                    color: $accent;
                }
            }
        }

        .btn {
            margin-left: 3rem;
            transition: height $transition, line-height $transition;
        }
    }

    .main-header {
        &.menu-scrolled {

            .btn-bg {
                background-color: #fff;
            }

            .btn-text {
                color: $accent;
            }

            .btn {

                &:hover {
                    .btn-text {
                        color: #fff;
                    }
                }
            }

            a {
                &:not(.btn) {
                    &:hover {
                        color: $accent;
                    }
                }
            }
        }

        &.menu-narrow {
            .btn {
                line-height: 39px;
                height: 38px;
            }
        }
    }

    .nav-btn {
        display: none;
    }

    .brand {
        width: 200px;
    }
}