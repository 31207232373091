.icon {
    max-height: 100%;
    max-width: 100%;
}

.icon-article {
    fill: $accent;
}


.icon-loader {
    height: 3rem;
    width: 3rem;
    margin: 0;
    opacity: 0;
    @extend %align-all;
}