.container-contact {

    .contact-list {
        list-style: none;
        padding: 0;
        margin-bottom: $padding-desktop;

        li {
            padding: 0 0 0 5rem;
            position: relative;
            margin-bottom: $padding-tablet;

            .icon {
                @extend %align-v;
                left: 0;
            }
        }

        .icon {
            height: 3rem;
            width: 3rem;
            margin-right: $padding-mobile;
        }
    }

    .contact-form {
        text-align: center;
        position: relative;

        &.form-loading {
            pointer-events: none;

            .form-row, button {
                opacity: .4;
            }

            .loader {
                opacity: 1;
            }
        }

        .form-submit-confirm {
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            margin-bottom: 0;
        }

        .small {
            font-size: 1.1rem;
            text-align: left;
            padding-bottom: 15px;
        }
    }

    .checkbox-styled {
        padding: 0;
    }
}

@media (min-width: $tablet-md) {
    .container-contact {
        display: flex;

        .contact-list {
            flex: 1;
        }

        .contact-form {
            flex: 1;

            .btn-gradient {
                float: right;
            }
        }
    }

    .container-contact {
        .contact-form {
            .form-submit-confirm {
                left: 0;
                right: auto;
                bottom: 13px;
                top: auto;
                margin-top: 0;
                padding-left: $grid-padding-mobile;
            }
        }
    }
}

@media (min-width: $desktop-min) {}