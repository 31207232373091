.section-benefits {}

.owl-item {
    //max-width: 400px;
}

.article-box {
    position: relative;
    padding: 8px;
    margin: 0 auto 3rem;
    max-width: 72vw;
    @extend %gradient-vertical;

    .article-icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 90px;
        height: 90px;
        background-color: #fff;

        .icon {
            left: 0;
            top: 0;
            width: auto;
            max-width: 60px;
            height: 60px;
        }
    }

    .article-content {
        padding: 90px 2rem 2rem;
        background-color: #fff;
        height: 100%;
        min-height: 445px;
        min-height: 394px;
        font-size: 1.6rem;
    }

    h3 {
        color: $accent;
        @extend %highlight;

        b {
            color: $header-dark;
        }
    }
}

.section-short-benefits {
    background-image: url('/bundles/website/images/bg-2.jpg');
    background-position: 50% 100%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    @extend %section-bar;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: .8;
        @extend %main-gradient;
    }
}

@media (max-width: $tablet-min) {
    .article-box {
        .article-content {
            font-size: 1.4rem;
        }
    }
}

@media (min-width: 500px) {
    .article-box {
        .article-content {
            min-height: 430px;
        }
    }
}

@media (min-width: $desktop-min) {}

@media (min-width: $tablet-max) {

    .article-list {
        display: flex;
        margin-right: -$padding-mobile;
        margin-left: -$padding-mobile;
        width: auto;

        .article-box {
            margin: 0 $padding-mobile;
            flex: 1;
        }
    }
}

@media (min-width: $desktop-lg) {
    .article-box {
        .article-content {
            font-size: 1.8rem;
        }
    }
}