%align-all {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

%align-v {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

%align-h {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

%underline {
    position: relative;

    &::after {
        content: '';
        height: 0;
        position: absolute;
        right: 0;
        left: 0;
        bottom: -3px;
        height: 2px;
        background-color: $accent;
        @extend %gradient-horizontal;
        transform: translateY(50%) scaleX(0);
        transform-origin: 0 0;
        transition: all .15s linear;
    }
}

%content-link {
    text-decoration: none;
    color: $accent;
    position: relative;

    &:after {
        content: '';
        height: 1px;
        background-color: $accent;
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        transition: border-color $transition;
    }
    &:hover,
    &:active,
    &:focus {
        &:after {
            @extend %gradient-horizontal;
        }
    }
}

%section-dark {
    color: #fff;
    background-color: $bg-dark;
}

%section-light {
    color: $main-color;
    background-color: #fff;

    h2, .h2, h3, .h3, h4, .h4 {
        color: $header-dark;
    }
}

%section-gray {
    color: $main-color;
    background-color: $section-gray;

    h2, .h2, h3, .h3, h4, .h4 {
        color: $header-dark;
    }
}

%btn-base {
    -webkit-appearance: none;
    background-color: transparent;
    border: 0 none;
    padding: 0 3rem;
    color: #fff;
    font-family: $font-headers;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    height: 50px;
    line-height: 50px;
    min-width: 200px;
    white-space: nowrap;
    display: inline-block;
    border-radius: 4px;
    cursor: pointer;

    &:active,
    &:focus,
    &:hover {
        outline: 0;
        box-shadow: none;
    }

    .icon {
        max-height: 1.6rem;
        max-width: 1.6rem;
        display: inline-block;
    }
}

%gradient-vertical {
    background-image: linear-gradient( rgb(251, 176, 59) 0%, rgb(212, 20, 90) 100%);
}

%gradient-horizontal {
    background-image: linear-gradient( 90deg, rgb(212, 20, 90) 0%, rgb(251, 176, 59) 100%);
}

%main-gradient {
    background-image: linear-gradient( 90deg, rgb(251, 176, 59) 0%, rgb(212, 20, 90) 100%);
}

%footer-gradient {
    background-image: linear-gradient( 180deg, rgb(58, 40, 69) 0%, rgb(49, 43, 71) 100%);
}

%highlight {
    b {
        display: block;
        font-weight: 900;
        font-size: 4rem;
        margin-bottom: $padding-mobile;
    }
}

%highlight-lg {
    @extend %highlight;
    b {
        font-size: 7rem;
    }
}

@media (min-width: $tablet-min) {
    %highlight {
        b {
            font-size: 5rem;
        }
    }

    %highlight-lg {
        b {
            font-size: 7.4rem;
        }
    }
}

@media (min-width: $desktop-min) {
    %underline {
        &:hover {
            &:after {
                transform: translateY(50%) scaleX(1);
            }
        }
    }


    %highlight-lg {
        b {
            font-size: 100px;
        }
    }
}

@media (min-width: $desktop-lg) {

    %highlight {
        b {
            font-size: 74px;
        }
    }

    %highlight-lg {
        b {
            font-size: 140px;
        }
    }
}