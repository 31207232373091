.main-footer {
    background-color: $bg-dark;
    background-image: url('../images/pattern.png');
    @extend %footer-gradient;
    @extend section;
    padding: 5rem 0;

    color: #fff;
    text-align: center;

    &.flex-container {
        display: flex;

        .container {
            text-align: left;

            &.smaller-font {
                text-align: right;
                p {
                    font-size: 1.4rem;
                    margin: 0;
                }
            }
        }
    }

    p {
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    a {
        color: #fff;
        text-decoration: underline;
        transition: color $transition;

        &:hover {
            color: $accent;
        }
    }
}