html {
    font-size: 62.5%;
}

body {
    font-size: 1.8rem;
    font-family: $font-main;
    line-height: 1.72;
    padding-top: $main-header-height;
    background-color: $bg-dark;
}

* {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 900;
    font-family: $font-headers;
    line-height: 1;
    margin: 0 0 .2em 0;
}

h1, .h1 {
    font-size: 3.2rem;
}

h2, .h2 {
    font-size: 3.2rem;
}

h3, .h3 {
    font-size: 2.1rem;
}

h4, .h4 {
    font-size: 2.2rem;
}

h5, .h5 {
    font-size: 2rem;
}

h6, .h6 {
    font-size: 1.6rem;
}

a {
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;

    &:hover,
    &:active,
    &:focus {
        outline: 0;
    }

    main & {
        @extend %content-link;
    }
}

article {
    p {
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

@media (min-width: $tablet-min) {

    h1, .h1 {
        font-size: 4rem;
    } // h2, .h2 {
    //     font-size: 3rem;
    // }
}


@media (min-width: $desktop-min) {
    h1, .h1 {
        font-size: 5rem;
    }

    h2, .h2 {
        font-size: 3.8rem;
    }

    h3, .h3 {
        font-size: 2.4rem;
    }

    h4, .h4 {
        font-size: 3rem;
    }
}

@media (min-width: $desktop-md) {
    h1, .h1 {
        font-size: 6rem;
    }

    h2, .h2 {
        font-size: 4.2rem;
    }
}

@media (min-width: $desktop-lg) {
    h1, .h1 {
        font-size: 8rem;
    }

    h2, .h2 {
        font-size: 5.8rem;
    }

    h3, .h3 {
        font-size: 3.2rem;
    }
}

.animated {
    //   opacity: 0;
}