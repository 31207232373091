@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/Lato-normal-400.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	src: url(../fonts/Lato-normal-700.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/Raleway-normal-400.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 700;
	src: url(../fonts/Raleway-normal-700.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 900;
	src: url(../fonts/Raleway-normal-900.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

