.cookies-message-container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    transition: transform .3s linear;
    font-size: .8em;
    z-index: 30;
    padding: $grid-padding-mobile;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);

    a {
        @extend %content-link;
    }

    p {
        margin: 0;
    }

    .btn {
        margin-top: $grid-padding-mobile;
    }
}

.cookies-container {
    text-align: center;
}

.cookies-accepted {
    transform: translateY(100%);
}