.main-container {
    overflow: hidden;
}

.container {
    width: 1170px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 $grid-padding-mobile;
}

.container-slider {
    overflow: hidden;
}

section {
    position: relative;
    padding: $padding-desktop 0;
}

.section-header {
    margin-bottom: $padding-desktop;
    text-align: center;
    font-size: 1.4rem;

    p {
        margin-bottom: 0;
    }
}

.section-dark {
    @extend %section-dark;
}

.section-light {
    @extend %section-light;
}

.section-gray {
    @extend %section-gray;
}

.section-image-left, .section-image-right {
    article {
        padding-top: 3rem;
    }
}

.section-image-right {}

.section-image-left {}

.floating-column {
    overflow: hidden;

    img {
        width: 100%;
        max-width: 100%;
        height: auto;
        max-height: 100%;
    }
}

.section-short {

    color: #fff;

    header {
        @extend %highlight-lg;
        text-align: center;
    }

    article {
        text-align: center;
        margin-top: $padding-tablet;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }

    .container {
        position: relative;
    }
}

@media (max-width: $tablet-md) {
    .section-short {
        padding-top: 7rem;
        padding-bottom: 7rem;

        article {
            display: none;
        }
    }

    .container-slider {
        padding-right: 0;
    }

    .section-image-left, .section-image-right {
        &:not(.section-main) {
            display: flex;
            flex-direction: column-reverse;
        }
    }
}

@media (min-width: $tablet-min) {
    .section-header {
        font-size: 1.8rem;
    }
}

// @media (min-width: $tablet-md) and (max-width: $desktop-min) {
//     .section-image-right {
//         .floating-column {
//             right: 64%;
//         }
//     }
// }
@media (min-width: $tablet-md) {

    .section-image-right, .section-image-left {
        article {
            width: 50%;
        }
    }

    .floating-column {
        position: absolute;
        top: 0;
        left: 50%;
        right: 0;
        height: 100%;

        img {
            position: absolute;
            top: 0;
            max-width: none;
            width: auto;

            .section-image-left & {
                right: 0;
            }

            .section-image-right & {
                left: 0;
            }
        }
    }

    .section-image-left {
        display: flex;
        flex-direction: row-reverse;

        article {
            float: right;
        }

        .floating-column {
            right: 50%;
            left: 0; //padding-right: $padding-tablet;
        }
    }


    .section-image-right {
        .floating-column {
            left: 50%;
        }
    }
}

@media (min-width: $desktop-min) {

    .section-image-right, .section-image-left {
        article {
            width: 65%;
        }
    }

    .section-image-left {
        .floating-column {
            right: 65%;
        }
    }

    .section-image-right {
        .floating-column {
            left: 65%;
        }
    }

    section {
        &.section-lg {
            min-height: 80vh;
        }
    }

    %section-bar {

        .container {
            display: flex;
            align-items: center;
        }

        header {
            flex: 1;
        }

        article {
            flex: 2;
            padding-left: $padding-tablet;
            margin-top: 0;
            text-align: left;
        }
    }
}

@media (min-width: $desktop-md) {
    section {
        padding: $padding-desktop-md 0;

        .section-header {
            margin-bottom: $padding-desktop-md;
        }
    }

    .section-image-left {
        .floating-column {
            right: 58%;
        }
    }

    .section-image-right {
        .floating-column {
            left: 58%;
        }
    }
}

@media (min-width: $desktop-lg) {
    %section-bar {
        article {
            padding-left: $padding-desktop;
        }
    }
}