$font-main: 'Lato', sans-serif;
$font-headers: 'Raleway', sans-serif;
$main-color: #515055;

$padding-mobile: 2rem;
$padding-tablet: $padding-mobile * 2;
$padding-desktop: $padding-mobile * 3;
$padding-desktop-md: $padding-mobile * 4;
$padding-desktop-lg: $padding-mobile * 5;

$grid-padding-mobile: 1.5rem;

$tablet-min: 600px;
$tablet-md: 992px;
$tablet-max: 1024px;
$desktop-min: 1025px;
$desktop-md: 1280px;
$desktop-lg: 1400px;

$accent: #d4145a;

$bg-dark: #342a46;
$header-dark: #312b47;
$header-light: $accent;
$menu-color: #bbbfe9;

$form-border: #e2e2e2;
$form-labels: #7d7d7d;

$section-gray: #f1f0f0;

$progress-bar-bg: #dbdbdb;

$error: #db4437;
$success: #4caf50;

$fill-light: #fff;
$fill-dark: #000;

$transition: .15s linear;

$main-header-height: 8rem;