.modal-container {
    background-color: #fff;
    width: 800px;
    max-width: 90%;
    padding: 6rem $padding-mobile $padding-mobile;
    border-radius: 5px;
    margin: 7rem auto;
    position: relative;
    text-align: center;

    .modal-header {
        color: $header-dark;
    }

    .register-description {
        font-size: 1.6rem;
        line-height: 1.4;
    }

    .mfp-close {
        color: $header-dark;
    }

     ::selection {
        background: $accent;
        color: #fff;
        opacity: 1;
    }
}

.modal-icon {
    @extend %gradient-horizontal;
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
    border: 8px solid #fff;
    padding: 2rem;
    position: absolute;
    top: -5rem;

    @extend %align-h;

    .icon {
        fill: #fff;
    }
}

.mfp-bg {
    background-color: rgba($bg-dark, .95);
}

.register-form {
    width: 475px;
    max-width: 100%;
    margin: $padding-mobile auto;
    position: relative;

    p.p.small {
        margin: 0 0 10px 0;
    }

    .form-row {
        text-align: left;

    }

    &.form-loading {
        pointer-events: none;

        .form-row, button {
            opacity: .4;
        }

        .loader {
            opacity: 1;
        }
    }

    .form-submit-confirm {
        padding-top: $grid-padding-mobile;
    }
}

.form-agreements-modal {
    padding-top: 4rem;

    &.form-loading {
        pointer-events: none;

        .modal-content {
            opacity: .4;
        }

        .loader {
            opacity: 1;
        }
    }

    .form-submit-confirm {
        padding-top: $grid-padding-mobile;
    }

    .form-info {
        margin-bottom: 3rem;
    }
}





/* overlay at start */

.mfp-fade.mfp-bg {
    opacity: 0;
    transition: all 0.15s ease-out;
}




/* overlay animate in */

.mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.8;
}




















/* overlay animate out */

.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}




















/* content at start */

.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    transition: all 0.15s ease-out;
}








/* content animate it */

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
}









/* content animate out */

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}

@media (min-width: $tablet-min) {
    .modal-container {
        .register-description {
            font-size: 1.8rem;
        }
    }
    .form-agreements-modal {
        padding: $padding-tablet;
    }
}