.visible-desktop {
    @media (max-width: $tablet-max) {
        display: none !important;
    }
}

.visible-mobile {
    @media (min-width: $desktop-min) {
        display: none !important;
    }
}

.hidden {
    display: none !important;
}

.success {
    color: $success;
}

.error {
    color: $error;
}

.container {
     ::selection {
        background: $accent;
        color: #fff;
        opacity: 1;
    }
}