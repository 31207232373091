p.p.small {
    font-size: 1.2rem;
    line-height: 1.4;
    width: 100%;
    text-align: left;
}

.btn {
    @extend %btn-base;
    @extend %gradient-horizontal;
    position: relative;
    line-height: 40px;
    text-align: center;

    .btn-bg {
        position: absolute;
        top: 2px;
        right: 2px;
        bottom: 2px;
        left: 2px;
        background-color: $bg-dark;
        border-radius: 2px;
        transition: left .3s cubic-bezier(0.76, -0.11, 0.21, 1.13);
    }

    .btn-text {
        position: relative;
        transition: color .3s cubic-bezier(0.76, -0.11, 0.21, 1.13);
    }

    &:hover {
        .btn-bg {
            left: 100%;
        }
    }
}

.btn-gradient {
    @extend %btn-base;
    @extend %gradient-horizontal;
    border: 0 none;
    position: relative;
    transition: box-shadow $transition;

    span, svg {
        pointer-events: none;
    }

    .icon {
        fill: #fff;
        margin-right: 8px;
        margin-top: -1px;
        height: 12px;
    }

    .btn-bg {
        position: absolute;
        top: 2px;
        right: 100%;
        bottom: 2px;
        left: 2px;
        background-color: $bg-dark;
        border-radius: 2px;
        transition: all $transition;
    }

    .btn-text {
        position: relative;
    }
}

input {
    &:focus {
        outline: 0;
    }
}

.form-row {
    margin: .5rem 0;
    transition: opacity $transition;
}

.form-label {
    //display: block;
    font-weight: 700; // transition: transform $transition;
    color: $form-labels;
    font-family: $font-main;

    .label-error & {
        color: $accent;
    }

    .is-dirty & {
        color: $form-labels;
    }
}

.form-input {
    border: 0;
    border-bottom: 1px solid $form-border;
    border-radius: 3px;
    box-shadow: none;
    outline: 0;
    padding: .5rem 1.5rem;
    width: 100%;
    min-height: 5rem;
    font-family: $font-main;
    line-height: 1.4;

    .label-error & {
        border-color: $accent;
    }
}

.form-description {
    font-size: 1rem;
    line-height: 1.6;
}

.form-required {
    color: $error;
    font-weight: bold;
}

.parsley-errors-list {
    list-style: none;
    color: $error;
    font-size: 1.4rem;
    position: absolute;
    right: 0;
    top: 1px; // bottom: 8px;
    line-height: 1.1;
    text-align: right;
    margin: 0;

    &#parsley-id-multiple-client_formregulations, &#parsley-id-multiple-register_email_formregulations {
        display: none !important;
    }
}

.parsley-error {
    border-radius: 0;
    border-bottom-width: 2px; //background-color: rgba($error, .1);
}

.autosize {
    resize: vertical;
}

.form-info {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.4;
    text-align: left;
}

.checkbox-styled {
    padding-top: 3rem;
    padding-bottom: 3rem;
    position: relative;

     ::selection {
        background: transparent;
        color: $main-color;
    }

    &.if-in-popup {
        padding: 0 0 20px 0 !important;
    }

    input[type=checkbox],
    input[type=radio] {
        position: absolute;
        clip: rect(1px, 1px, 1px, 1px);
        width: 0;
        height: 0;
    }

    input[type=checkbox] {
        &:checked {
            +label {
                &:before {
                    background-image: url('../images/icons/check.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 12px auto;
                    border-color: $accent;
                    background-color: $accent;
                }
            }
        }
    }

    input[type=radio] {
        &:checked {
            +label {
                &:before {
                    border-color: $accent;
                    background-color: $accent;
                    border-width: 2px;
                    box-shadow: inset 0 0 0 3px #fff;
                }
            }
        }

        +label {
            &:before {
                border-radius: 50%;
                box-shadow: inset 0 0 0 3px #fff;
            }
        }
    }

    label {
        cursor: pointer;
        font-size: 1.2rem;
        line-height: 1.4;
        display: block;
        width: 100%;
        position: relative;
        padding-left: 4rem;
        text-align: left;


        &:before {
            content: "";
            display: inline-block;
            width: 1.8rem;
            height: 1.8rem;
            border: 1px solid darken($accent, 15%);
            background-color: #fff;
            position: absolute;
            top: 0;
            left: 0; //box-shadow: inset 0 0 0 2px #fff;
        }
    }

    &.parsley-error {
        label {
            color: $error;
        }

        +.parsley-errors-list {
            margin-top: -2rem;
        }
    }

    .parsley-errors-list {
        top: 6px;
    }
}

.form-submit-confirm {
    color: $success;
    font-size: 1.6rem;
    display: none;
    margin-bottom: 0;
}

.mdl-textfield__input {
    border-radius: 0;
}

// material styles
.mdl-textfield {
    width: 100%;

    &.is-invalid {
        .mdl-textfield__input {
            @extend .form-input; // border-radius: 0;
            // border-bottom-width: 2px;
            // background-color: rgba($error, .1);
        }
        .mdl-textfield__label {
            @extend .form-label;
            font-size: 1.6rem;
        }
    }
}

.mdl-textfield--floating-label {

    &.is-focused, &.is-dirty, &.has-placeholder {
        .mdl-textfield__label {
            top: -5px;
            color: $accent;
            padding-left: 0;
            font-size: 100%;
        }
    }
}

.mdl-textfield__label {
    top: 34px; //left: 1.5rem;
    padding-left: 1.5rem;
    &:after {
        @extend %gradient-horizontal; //background-color: $accent;
    }
}

.autoExpand {
    overflow: hidden;
}

@media (min-width: $tablet-md) {
    .form-submit-confirm {
        margin-top: 0;
    }
}

@media (min-width: $desktop-min) {

    .btn-gradient {
        &.btn-lg {
            line-height: 60px;
            height: 60px;
            min-width: 240px;
        }

        &:not(.hover-shadow) {
            &:hover {
                // box-shadow: 0px 0px 28.5px 9.5px rgba(32, 28, 47, 0.3);
                box-shadow: 0px 24px 28.5px 9.5px rgba(32, 28, 47, 0.2);
            }
        }
    }
}