.section-main {
    position: relative;
    color: #fff;
    z-index: 5;
    padding-bottom: 0;

    .main-article-content {
        max-width: 560px;
        margin: 4rem 0;
    }

    .btn-gradient {
        .icon {
            fill: #fff;
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 100%;
        right: 0;
        height: 0;
        border-style: solid;
        border-width: 10vw 100vw 0 0;
        transition: top $transition, border $transition;
        border-color: $bg-dark transparent transparent transparent;
        z-index: 0;
    }

    .video-visible & {
        &:before {
            top: 0;
            border-top: 0; //top: 0; // animation: angle .5s; // border-width: 0;
        }
    }

    .btn-gradient {
        box-shadow: 0px 0px 28.5px 9.5px rgba(32, 28, 47, 0.3);

        &:hover {
            box-shadow: 0px 24px 28.5px 9.5px rgba(32, 28, 47, 0.8);
        }
    }

    .floating-column {
        overflow: visible;
        display: flex;
        align-items: center;

        img {
            width: auto;
            max-width: 180%;
            margin-left: -20%;
            position: relative;
            backface-visibility: hidden;
            transform-style: preserve-3d;
        }
    }
}

.section-video {
    padding: 0;
    position: relative;
    text-align: center;
    height: 5vw;
    //min-height: 50vw;

    &:before, &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: all .3s ease-in-out;

        .video-playing & {}
    }

    &:before {
        background-color: #fff;
    }

    &:after {
        //background-image: url('/bundles/website/images/video-bg.jpg');
        background-size: cover;
        opacity: .1;
    }

    video {
        width: 100%;
        height: auto;
    }

    .video-visible & {
        &:before, &:after {
            bottom: 50%;
            top: 50%;
        }
    }
}

.video-controls {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 $padding-mobile 8px $padding-mobile;
    border-bottom: 1px solid $progress-bar-bg;
    visibility: hidden;

    .video-visible & {
        visibility: visible;
    }

    .video-control {
        height: 3rem;
        width: 4rem;
        cursor: pointer;
    }

    &.video-playing {
        .video-trigger {
            display: none;
        }
    }

    &.video-paused {
        .video-pause {
            display: none;
        }
    }
}

.video-play-btn {
    @extend %align-all;
    height: 4rem;
    width: 4rem;
    cursor: pointer;
    z-index: 10;
    transition: all $transition;

    .video-visible & {
        height: 0;
        width: 0;
    }

    .video-playing & {}
}

.video-pause-btn {
    height: 3rem;
    width: 3rem;
    cursor: pointer;
}

.progress {
    width: 900px;
    height: 11px;
    max-width: 90%;
    cursor: pointer;
    position: relative;
    margin-left: 2rem;

    &:before {
        content: '';
        position: absolute;
        top: 4px;
        right: 0;
        bottom: 4px;
        left: 0;
        background-color: $progress-bar-bg;
    }

    .progress-bar {
        position: absolute;
        top: 4px;
        bottom: 4px;
        left: 0;
        height: 3px;
        @extend %gradient-horizontal;
        transition: all .25s linear;
    }
}

@media (max-width: $tablet-min) {
    .section-main {
        .floating-column {
            img {
                margin-bottom: -6rem;
                margin-right: -20%;
                width: 180%;
                max-width: none;
            }
        }
    }

    .parallax-container {
        padding-top: 1rem;
    }
}


@media (min-width: $tablet-min) and (max-width: 1023px) {
    .section-main {
        .floating-column {
            img {
                margin-right: -10%;
                margin-left: auto;
                max-width: 100%;
            }
        }
    }
}

@media (max-width: $tablet-md) {
    .main-article {
        h1 {
            margin-bottom: 4rem;
        }
    }

    .main-article-content {
        display: none;
    }

    .section-video {
        .video-visible & {
            z-index: 100;
        }
    }
}

@media (min-width: $tablet-max) {
    .section-main {
        footer {
            padding-bottom: 3rem;
        }
    }
}

@media (min-width: $desktop-min) {
    .section-main {
        .floating-column {
            margin-top: 3rem;
        }
    }
}

@media (min-width: $desktop-lg) {

    .main-article-content {
        p {
            font-size: 2.2rem;
        }
    }

    .section-main {
        .floating-column {
            img {
                margin-left: 0;
            }
        }
    }
}