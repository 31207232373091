.section-functions {
    header {
        margin-bottom: $padding-tablet;

        h3 {
            color: $accent;
            font-size: 2.6rem;
        }
    }

    +.section-functions {
        padding-top: 0;
        margin-top: -$padding-mobile;
    }

    .article-item {
        margin: $padding-desktop 0;
    }
}

.section-short-functions {
    @extend %section-bar;
}

.section-short-clients {}

.slider-logos {
    figure {
        width: auto;
        max-width: 200px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: .4;

        img {
            width: auto;
            max-height: 28px;
            min-height: 28px;
            max-width: 90%;
        }
    }

    .owl-item {
        max-width: 60vw;

        &:not(:last-of-type) {
            &:after {
                content: '';
                position: absolute;
                top: 15%;
                right: 0;
                bottom: 15%;
                border-right: 1px solid rgba(#000, .1);
            }
        }
    }
}


@media (min-width: $tablet-min) {
    .section-functions {

        .article-content {
            display: flex;
            margin-left: -$grid-padding-mobile;
            margin-right: -$grid-padding-mobile;
        }

        .article-item {
            margin: $grid-padding-mobile;
            width: 50%;
        }
    }
}

@media (min-width: $desktop-min) {
    .section-functions {
        header {
            p {
                font-size: 2rem;
            }

            h3 {
                font-size: 3rem;
            }
        }
    }

    .slider-logos {
        .owl-item {
            max-width: 220px;
        }

        .owl-stage {
            margin: auto;
        }
    }
}

@media (min-width: $tablet-md) {
    .section-functions {
        +.section-functions {
            margin-top: 0;
        }
    }


    .section-functions {
        &.section-image-left, &.section-image-right {
            min-height: 735px;
        }
    }
}

@media (min-width: $desktop-lg) {
    .section-functions {
        header {
            h3 {
                font-size: 4rem;
            }
        }
    }

    .section-image-left {
        article {
            header, .article-content {
                padding-left: 2rem;
            }
        }
    }

    .section-image-right {
        article {
            header, .article-content {
                padding-right: 2rem;
            }
        }
    }
}